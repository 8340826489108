<template>
  <div>
    <Header />
    <v-card elevation="2" class="mx-auto header-row mb-5 pa-0" width="80%">
      <v-row class="ma-0 pa-0">
        <v-col :cols="12" :lg="8" :md="8" :sm="12" class="d-flex align-center"
          ><p class="py-0 pl-3 my-0 header-text text-white text-uppercase">
            
          </p></v-col
        >
        <v-col :cols="12" :lg="4" :md="4" :sm="12">
          <v-btn
            @click="languageChange('hu')"
            class="mr-3 ml-0 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >hu</v-btn
          >
          <v-btn
            @click="languageChange('en')"
            class="mr-1 ml-3 float-right"
            color="white"
            dark
            outlined
            elevation="0"
            >en</v-btn
          >
          
        </v-col>
      </v-row>
    </v-card>
    <v-card width="80%" class="mx-auto pa-0 ma-0" v-if="felevek.length > 0">
      <v-row
        class="mx-auto text-center header-font ma-0 pa-0 pt-5 pb-5"
        justify="center"
      >
        <v-col
          :cols="12"
          :lg="2"
          :md="3"
          :sm="12"
          class="mx-2 border rounded d-flex align-center justify-center"
          ><v-btn color="transparent" elevation="0" @click="colorChange(0)">{{
            lang.alapallapot
          }}</v-btn></v-col
        >
        <v-col
          :cols="12"
          :lg="2"
          :md="3"
          :sm="12"
          class="mx-2 border rounded d-flex align-center justify-center"
          >{{ lang.tajekoztato_1 }}</v-col
        >
        <v-col
          :cols="12"
          :lg="2"
          :md="3"
          :sm="12"
          class="mx-2 border rounded d-flex align-center justify-center"
          ><p
            color="transparent"
            class="textflex ma-0 pa-0"
            @click="colorChange(1)"
          >
            {{ lang.felveheto_tantargyak }}
          </p></v-col
        >
        <v-col
          :cols="12"
          :lg="2"
          :md="3"
          :sm="12"
          class="mx-2 border rounded d-flex align-center justify-center"
          >{{ lang.tajekoztato_2 }}</v-col
        >
      </v-row>

      <v-row class="ma-0 pa-0 mx-6 text-body-2 text-center">
        <p class="ma-0 pa-0">
          <b>{{ lang.tajekoztato_cim1 }}</b> {{ lang.tajekoztato_szoveg1 }}
        </p>
        <p class="ma-0 pa-0">
          <b>{{ lang.tajekoztato_cim2 }}</b> {{ lang.tajekoztato_szoveg2 }}
        </p>
        <p class="ma-0 pa-0">
          <b>{{ lang.tajekoztato_cim3 }}</b> {{ lang.tajekoztato_szoveg3 }}
        </p>
      </v-row>
      <v-divider class="mx-4"></v-divider>
      <v-row class="ma-0 pa-0 mb-4">
        <v-col
          :cols="12"
          :lg="4"
          :md="12"
          :sm="12"
          class="pa-0 ma-0 text-center"
          >{{ lang.szak }}: <b v-if="langName == 'hu'">{{ information.F3 }}</b
            ><b v-else>{{ information.F16 }}</b></v-col
        >
        <v-col
          :cols="12"
          :lg="4"
          :md="12"
          :sm="12"
          class="pa-0 ma-0 text-center"
          >{{ lang.szakirany }}: <b>{{ information.F7 }}</b></v-col
        >
        <v-col
          :cols="12"
          :lg="4"
          :md="12"
          :sm="12"
          class="pa-0 ma-0 text-center"
          >{{ lang.tanterv }}: <b>{{ information.F10 }}</b></v-col
        >
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-divider class="mx-5"></v-divider>
        <p class="w-100 text-center ma-0 pa-0 my-3">
          {{ lang.jelmagyarazat }}
        </p>
      </v-row>
      <v-row class="pa-0 mx-auto my-1 text-center">
        <v-col :cols="12" :lg="3" :md="12" :sm="12">
          <div class="color-box-yellow"></div>
          {{ lang.kivalasztott_tantargy }}
        </v-col>
        <v-col :cols="12" :lg="3" :md="12" :sm="12"
          ><div class="color-box-darkblue"></div>
          -
          <div class="color-box-lightblue"></div>
          {{ lang.elokovetelmeny }}</v-col
        >
        <v-col :cols="12" :lg="3" :md="12" :sm="12"
          ><div class="color-box-green"></div>
          {{ lang.felveheto_tantargyak }}</v-col
        >
        <v-col :cols="12" :lg="3" :md="12" :sm="12"
          ><div class="color-box-darkred"></div>
          -
          <div class="color-box-lightred"></div>
          {{ lang.raepules }}</v-col
        >
      </v-row>
      <v-simple-table class="ma-0 pa-0 mx-3 pb-3">
        <thead>
          <tr>
            <th
              v-for="(ind, index) in felevek.length + 1"
              :key="index"
              class="border text-center m-0 p-0"
            >
              <div v-if="index == 0">
                <sub>{{ lang.kredit }} </sub>/<sup>
                  {{ lang.szak_keresese_ev }}</sup
                >
              </div>
              <div
                v-else
                :style="`padding: 1vh; background-color: ${
                  bgColors([
                    felevek[ind - 2].creditPerFelev -
                      felevek[ind - 2].nullCreditSub,
                    felevCredits[ind - 2],
                  ])[0]
                }; color:${
                  bgColors([
                    felevek[ind - 2].creditPerFelev -
                      felevek[ind - 2].nullCreditSub,
                    felevCredits[ind - 2],
                  ])[1]
                }`"
              >
                <div class="text-center">
                  {{ ind - 1 }}
                </div>
                <div>
                  {{
                    felevek[ind - 2].creditPerFelev -
                    felevek[ind - 2].nullCreditSub
                  }}
                  / {{ felevCredits[ind - 2] }}
                </div>
                <!-- <div>{{typeof(felevek[ind-2].creditPerFelev)}} {{typeof(felevek[ind-2].nullCreditSub)}}</div> -->
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="text-center">
          <tr v-for="credit in maxRow" :key="credit">
            <td class="border">{{ credit }}</td>
            <template v-for="felev in felevek">
              <template v-for="f in felev.subs">
                <td
                  class="border"
                  :key="f.Targynev"
                  v-if="f.eddigi_kredit + 1 == credit"
                  :rowspan="f.Targy_kredit != 0 ? f.Targy_kredit : 1"
                  :style="`background-color: ${f.color}; color: ${f.text_color};`"
                  @click="selectSubject(f)"
                  @drop="onDrop($event, felev.sem)"
                  @dragover.prevent
                  @dragenter.prevent
                  draggable="true"
                  @dragstart="startDrag($event, f)"
                >
                  <v-tooltip
                    bottom
                    :key="f.Targynev"
                    color="uni_blue"
                    close-delay="500"
                    open-delay="500"
                    :style="`color: ${f.text_color}`"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        href="javascript:void(0)"
                        :style="`color: ${f.text_color}`"
                        v-bind="attrs"
                        v-on="on"
                        @click="tematikaMegtekintes(f.Targykod)"
                        v-if="Number.isNaN(Number.parseFloat(f.Targynev))"
                        >{{ f.Targynev }}<br />{{ f.Targykod }}</a
                      >
                      <input
                        type="checkbox"
                        :id="f.Targykod"
                        class="text-warning mx-2 pt-2"
                        v-if="Number.isNaN(Number.parseFloat(f.Targynev))"
                        @click="countCredits(f, -1)"
                      />
                    </template>
                    <p class="m-0 p-0">
                      {{ lang.table_megnevezes }}: <span>{{ f.Targynev }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_beszamolas_fajtaja }}:
                      <span>{{ f.F27 }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_kreditpont }}:
                      <span>{{ f.Targy_kredit }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_ajanlott_felev }}:
                      <span>{{ f.Felev_szam }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_elotanulmanyi_kov }}:
                      <span>{{ f.Elokovetelmeny_leiras_1 }}</span
                      ><br /><span>{{ f.Elokovetelmeny_leiras_2 }}</span>
                    </p>
                  </v-tooltip>
                </td>
              </template>
            </template>
          </tr>
          <tr class="border">
            <td
              v-for="(fel, ind) in felevek.length + 1"
              :key="ind"
              class="border p-0 m-0"
            >
              <div v-if="ind == 0">
                {{ lang.kredit }}
              </div>
              <div
                v-else
                :style="`padding: 1vh; background-color: ${
                  bgColors([
                    felevek[fel - 2].creditPerFelev -
                      felevek[fel - 2].nullCreditSub,
                    felevCredits[fel - 2],
                  ])[0]
                }; color:${
                  bgColors([
                    felevek[fel - 2].creditPerFelev -
                      felevek[fel - 2].nullCreditSub,
                    felevCredits[fel - 2],
                  ])[1]
                }`"
              >
                <div class="text-center">
                  {{ fel - 1 }}
                </div>
              </div>
            </td>
          </tr>
          <tr class="border">
            <td
              class="text-center"
              :colspan="felevek.length + 1"
              :style="`font-size: 20px !important; background-color: ${footerColor[0]}; color: ${footerColor[1]}`"
            >
              {{ lang.kotelelezo_tantargyak_ossz_kredit }}
              <b>{{ allCredit - allNullCreditSub }}</b> /
              <b>{{ getAllCredit }}</b>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <v-card width="80%" class="mx-auto ma-0 pa-0 my-3">
      <p class="display-1 text-center font-weight-bold pa-0 py-3">
        {{ lang.tantargycsoportok }}
      </p>
      <v-row class="mx-auto ma-0 pa-0">
        <v-col
          :cols="12"
          :lg="6"
          :md="12"
          v-for="(types, index) in secondSubjects"
          :key="index"
          class="pa-0 ma-0"
        >
          <v-card
            v-if="!types.name.includes('Kötelező tárgyak')"
            class="pa-0 mx-3 my-2"
            elevation="2"
          >
            <v-row class="pa-0 ma-0">
              <v-col :cols="12" :lg="6" :md="6" :sm="6"
                ><b>{{ types.name }}</b></v-col
              >
              <v-col
                :cols="12"
                :lg="6"
                :md="6"
                :sm="6"
                v-if="types.credit != null"
                ><b>
                  {{ lang.teljesitendo_kredit }}: {{ types.credit }}</b
                ></v-col
              >
              <v-col
                :cols="12"
                :lg="6"
                :md="6"
                :sm="6"
                v-if="types.tantargyak_szama != null"
                ><b>
                  {{ lang.elvegzett_tantargy_csoport }}
                  {{ types.tantargyak_szama }}</b
                ></v-col
              >
            </v-row>
            <p class="pa-0 ma-0 px-2 py-2 caption">
              {{ types.leiras }}
            </p>
            <v-simple-table v-if="types.children.length == 0" class="pa-1">
              <thead v-if="types.credit != null" class="border-top rounded">
                <tr class="rounded">
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_sorszam }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_megnevezes }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_kod }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.teljesitve }}
                  </th>
                </tr>
              </thead>
              <thead
                v-else-if="types.tantargyak_szama != null"
                class="border-top rounded"
              >
                <tr class="rounded">
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_sorszam }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_megnevezes }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.table_kod }}
                  </th>
                  <th
                    :style="`background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.teljesitve }}
                  </th>
                </tr>
              </thead>
              <thead
                v-else-if="
                  types.credit == null && types.tantargyak_szama == null
                "
                class="border-top rounded"
              >
                <tr class="rounded">
                  <th>{{ lang.table_sorszam }}</th>
                  <th>{{ lang.table_megnevezes }}</th>
                  <th>{{ lang.table_kod }}</th>
                  <th>{{ lang.teljesitve }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(sub, id) in types.subjects" :key="id">
                  <td>{{ id + 1 }}</td>
                  <v-tooltip
                    bottom
                    :key="sub.Targykod"
                    color="uni_blue"
                    close-delay="500"
                    open-delay="500"
                    :style="`color: ${sub.text_color}`"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <td
                        class=""
                        v-bind="attrs"
                        v-on="on"
                        @click="selectSubject(sub)"
                        :style="`background-color: ${sub.color}; color: ${sub.text_color};`"
                      >
                        <a
                          href="javascript:void(0)"
                          @click="tematikaMegtekintes(sub.Targykod)"
                          :style="`color: ${sub.text_color};`"
                          >{{ sub.Targynev }}</a
                        >
                      </td>
                    </template>
                    <p class="m-0 p-0">
                      {{ lang.table_megnevezes }}:
                      <span>{{ sub.Targynev }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_beszamolas_fajtaja }}:
                      <span>{{ sub.F27 }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_kreditpont }}:
                      <span>{{ sub.Targy_kredit }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_ajanlott_felev }}:
                      <span>{{ sub.Felev_szam }}</span>
                    </p>
                    <p class="m-0 p-0">
                      {{ lang.table_elotanulmanyi_kov }}:
                      <span>{{ sub.Elokovetelmeny_leiras_1 }}</span
                      ><br /><span>{{ sub.Elokovetelmeny_leiras_2 }}</span>
                    </p>
                  </v-tooltip>
                  <td class="">{{ sub.Targykod }}</td>
                  <td class="align-content-center">
                    <input
                      class="my-1 mx-auto align-content-center w-100"
                      type="checkbox"
                      name=""
                      :id="sub.Targykod"
                      @click="countCredits(sub, index)"
                    />
                  </td>
                </tr>
                <tr>
                  <td
                    class="text-center rounded"
                    :colspan="4"
                    v-if="types.credit != null"
                    :style="`padding: 1vh; background-color: ${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[0]
                    }; color:${
                      bgColors([
                        types.credit,
                        getSecondAllCredit[index].credit,
                      ])[1]
                    }`"
                  >
                    {{ lang.kotelelezo_tantargyak_ossz_kredit }}
                    <b>{{ types.credit }}</b> /
                    <b>{{ getSecondAllCredit[index].credit }}</b>
                  </td>
                  <td
                    class="text-center rounded"
                    :colspan="4"
                    v-if="types.tantargyak_szama != null"
                    :style="`padding: 1vh; background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color:${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.elvegzett_tantargy_csoport }}
                    <b>{{ types.tantargyak_szama }}</b> /
                    <b>{{ getSecondAllCredit[index].db }}</b>
                  </td>
                  <td
                    class="text-center rounded"
                    :colspan="4"
                    v-if="types.name.includes('Testnevelés')"
                    :style="`padding: 1vh; background-color: ${
                      bgColors([
                        parseInt(types.subjects.length),
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color:${
                      bgColors([
                        parseInt(types.subjects.length),
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    {{ lang.elvegzett_osszes_tantargy }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div v-else>
              <div v-for="(child, childKey) in types.children" :key="childKey">
                <p class="mx-2 mt-3">
                  <b>{{ child.name }}</b>
                </p>
                <p class="mx-2">
                  {{ child.leiras }}
                </p>
                <v-simple-table class="mx-2">
                  <thead class="border-top rounded" v-if="types.credit != null">
                    <tr>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_sorszam }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_megnevezes }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_kod }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.credit,
                            getSecondAllCredit[index].credit,
                          ])[1]
                        }`"
                      >
                        {{ lang.teljesitve }}
                      </th>
                    </tr>
                  </thead>
                  <thead
                    class="border-top"
                    v-else-if="types.tantargyak_szama != null"
                    :style="`background-color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[0]
                    }; color: ${
                      bgColors([
                        types.tantargyak_szama,
                        getSecondAllCredit[index].db,
                      ])[1]
                    }`"
                  >
                    <tr
                      :style="`background-color: ${
                        bgColors([
                          types.tantargyak_szama,
                          getSecondAllCredit[index].db,
                        ])[0]
                      }; color: ${
                        bgColors([
                          types.tantargyak_szama,
                          getSecondAllCredit[index].db,
                        ])[1]
                      }`"
                    >
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_sorszam }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_megnevezes }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[1]
                        }`"
                      >
                        {{ lang.table_kod }}
                      </th>
                      <th
                        :style="`background-color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[0]
                        }; color: ${
                          bgColors([
                            types.tantargyak_szama,
                            getSecondAllCredit[index].db,
                          ])[1]
                        }`"
                      >
                        {{ lang.teljesitve }}
                      </th>
                    </tr>
                  </thead>
                  <thead
                    v-else-if="
                      types.credit == null && types.tantargyak_szama == null
                    "
                    class="border-top rounded"
                  >
                    <tr>
                      <th>{{ lang.table_sorszam }}</th>
                      <th>{{ lang.table_megnevezes }}</th>
                      <th>{{ lang.table_kod }}</th>
                      <th>{{ lang.teljesitve }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sub, id) in child.subjects" :key="id" class="">
                      <td>{{ id + 1 }}</td>
                      <v-tooltip
                        bottom
                        :key="sub.Targykod"
                        color="uni_blue"
                        close-delay="500"
                        open-delay="500"
                        :style="`color: ${sub.text_color}`"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <td
                            class="border"
                            v-bind="attrs"
                            v-on="on"
                            @click="selectSubject(sub)"
                            :style="`background-color: ${sub.color}; color: ${sub.text_color};`"
                          >
                            <a
                              href="javascript:void(0)"
                              @click="tematikaMegtekintes(sub.Targykod)"
                              :style="`color: ${sub.text_color};`"
                              >{{ sub.Targynev }}</a
                            >
                          </td>
                        </template>
                        <p class="m-0 p-0">
                          {{ lang.table_megnevezes }}:
                          <span>{{ sub.Targynev }}</span>
                        </p>
                        <p class="m-0 p-0">
                          {{ lang.table_beszamolas_fajtaja }}:
                          <span>Vizsga</span>
                        </p>
                        <p class="m-0 p-0">
                          {{ lang.table_kreditpont }}:
                          <span>{{ sub.Targy_kredit }}</span>
                        </p>
                        <p class="m-0 p-0">
                          {{ lang.table_ajanlott_felev }}:
                          <span>{{ sub.Felev_szam }}</span>
                        </p>
                        <p class="m-0 p-0">
                          Mikor:
                          <span>{{
                            sub.mikor == "soha" ? lang.soha : sub.mikor
                          }}</span>
                        </p>
                        <p class="m-0 p-0">
                          {{ lang.table_elotanulmanyi_kov }}:
                          <span>{{ sub.Elokovetelmeny_leiras_1 }}</span
                          ><br /><span>{{ sub.Elokovetelmeny_leiras_2 }}</span>
                        </p>
                      </v-tooltip>
                      <td class="">{{ sub.Targykod }}</td>
                      <td class="align-content-center">
                        <input
                          class="my-1 mx-auto align-content-center w-100"
                          type="checkbox"
                          name=""
                          :id="sub.Targykod"
                          @click="countCredits(sub, index)"
                          :disabled="sub.done"
                        />
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
              <div>
                <div
                  class="text-center rounded"
                  :colspan="4"
                  v-if="types.credit != null"
                  :style="`padding: 1vh; background-color: ${
                    bgColors([
                      types.credit,
                      getSecondAllCredit[index].credit,
                    ])[0]
                  }; color: ${
                    bgColors([
                      types.credit,
                      getSecondAllCredit[index].credit,
                    ])[1]
                  }`"
                >
                  {{ lang.kotelelezo_tantargyak_ossz_kredit }}
                  <b>{{ types.credit }}</b> /
                  <b>{{ getSecondAllCredit[index].credit }}</b>
                </div>
                <div
                  class="text-center rounded"
                  :colspan="4"
                  v-if="types.tantargyak_szama != null"
                  :style="`padding: 1vh; background-color: ${
                    bgColors([
                      types.tantargyak_szama,
                      getSecondAllCredit[index].db,
                    ])[0]
                  }; color: ${
                    bgColors([
                      types.tantargyak_szama,
                      getSecondAllCredit[index].db,
                    ])[1]
                  }`"
                >
                  {{ lang.elvegzett_tantargy_csoport }}
                  <b>{{ types.tantargyak_szama }}</b> /
                  <b>{{ getSecondAllCredit[index].db }}</b>
                </div>
                <div
                  class="text-center rounded"
                  :colspan="4"
                  v-if="types.name.includes('Testnevelés')"
                  :style="`padding: 1vh; background-color: ${
                    bgColors([
                      types.subjects.length,
                      getSecondAllCredit[index].db,
                    ])[0]
                  }; color: ${
                    bgColors([
                      types.subjects.length,
                      getSecondAllCredit[index].db,
                    ])[1]
                  }`"
                >
                  {{ lang.elvegzett_osszes_tantargy }}
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-divider class="mx-5"></v-divider>
        <p class="display-1 w-100 text-center ma-0 pa-0 my-3">
          {{ lang.jelmagyarazat }}
        </p>
      </v-row>
      <v-row class="pa-0 mx-auto my-3 text-center">
        <v-col :cols="12" :lg="3" :md="12" :sm="12">
          <div class="color-box-yellow"></div>
          {{ lang.kivalasztott_tantargy }}
        </v-col>
        <v-col :cols="12" :lg="3" :md="12" :sm="12"
          ><div class="color-box-darkblue"></div>
          -
          <div class="color-box-lightblue"></div>
          {{ lang.elokovetelmeny }}</v-col
        >
        <v-col :cols="12" :lg="3" :md="12" :sm="12"
          ><div class="color-box-green"></div>
          {{ lang.felveheto_tantargyak }}</v-col
        >
        <v-col :cols="12" :lg="3" :md="12" :sm="12"
          ><div class="color-box-darkred"></div>
          -
          <div class="color-box-lightred"></div>
          {{ lang.raepules }}</v-col
        >
      </v-row>
    </v-card>
    <div class="text-center">
      <v-dialog v-model="dialog" width="60%">
        <v-card class="pt-5">
          <v-card-text>
            <TantargyTematika :subject="subject" :lang="lang" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-overlay :value="loadingCircular" opacity="0.9">
      <v-progress-circular
        indeterminate
        size="250"
        width="10"
        color="uni_blue2"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import TantargyTematika from "@/components/TantargyTematika.vue";
import Header from "@/components/Header.vue";
import hu from "@/plugins/hu.json";
import en from "@/plugins/en.json";
export default {
  props: ["mintaterv"],
  components: {
    TantargyTematika,
    Header,
  },
  data: () => ({
    lang: en,
    langName: "hu",
    dialog: false,
    loadingCircular: false,
    subjects: [],
    subject: {},
    felevek: [],
    allSubjects: [],
    maxRow: 0,
    felevCredits: [],
    information: {},
    allCredit: 0,
    getAllCredit: 0,
    felevekAlap: [],
    maxRowAlap: 0,
    utokovetelmenyColors: [
      "#3c0000",
      "#a20000",
      "#ff0909",
      "#ff6f6f",
      "#EC407A",
      "#f38caf",
      "#f8bbd0",
      "#fce8ef",
    ],
    elokovetelmenyColors: [
      "#061d30",
      "#135a91",
      "#1a78c2",
      "#1d87da",
      "#63b5f6",
      "#90caf9",
      "#bcdffb",
      "#e8f4fd",
    ],
    allCreditColor: ["white", "black"],
    freeSubsCount: 0,
    secondSubjects: [],
    getSecondAllCredit: [],
    URLSamplePlan: {
      id: "",
    },
    allNullCreditSub: 0,
  }),
  async mounted() {
    console.log(this.$route.params.id);
    // this.URLSamplePlan.id = this.$store.state.matrix
    this.URLSamplePlan.id = this.$route.params.id;
    if (this.$route.params.lang == "hu") {
      this.langName = "hu";
      this.lang = hu;
    } else {
      this.langName = "en";
      this.lang = en;
    }
    this.getDatas();
  },
  computed: {
    bgColors() {
      return (numbers) => [
        `${
          numbers[0] <= numbers[1] && numbers[0] != null && numbers[0] > 0
            ? "#43A047"
            : "white"
        }`,
        `${
          numbers[0] <= numbers[1] && numbers[0] != null && numbers[0] > 0
            ? "white"
            : "black"
        }`,
      ];
    },
    footerColor() {
      if (this.allCredit == this.getAllCredit) {
        return ["#43A047", "white"];
      } else {
        return ["white", "black"];
      }
    },
  },
  methods: {
    languageChange(type) {
      if (type == "en") {
        this.lang = en;
        this.langName = "en";
      } else {
        this.lang = hu;
        this.langName = "hu";
      }
      this.$cookies.set("lang", type);
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.Targykod);
      var itemJson = JSON.stringify(item);
      evt.dataTransfer.setData("item", itemJson);
    },

    onDrop(evt, list) {
      const itemID = evt.dataTransfer.getData("itemID");
      const firstItem = JSON.parse(evt.dataTransfer.getData("item"));
      var tantargyak = this.subjects.subjects;
      var cb = document.getElementById(firstItem.Targykod);

      this.felevek[parseInt(firstItem.Felev_szam) - 1].subs.forEach(
        (element) => {
          if (Number.isNaN(Number.parseFloat(element.Targykod))) {
            var cb = document.getElementById(element.Targykod);
            if (cb.checked == true && firstItem.Felev_szam != list) {
              this.felevCredits[firstItem.Felev_szam - 1] -= parseInt(
                element.Targy_kredit
              );
              this.getAllCredit -= parseInt(element.Targy_kredit);
              cb.checked = false;
            }
          }
        }
      );

      var t = [];
      t[0] = firstItem;
      var szamlalo = 0;
      var van = true;
      var osszes = [];

      while (van == true) {
        szamlalo++;
        var ret = this.utoKovetelmenyek(t, tantargyak, szamlalo);
        van = ret.van;
        t = ret.utokov;
        ret.utokov.forEach((element) => {
          osszes.push(element);
        });
      }
      this.felevek.forEach((item) => {
        item.subs.forEach((element) => {
          element.color = "white";
          element.text_color = "#038cac";
        });
      });
      osszes.forEach((item) => {
        this.felevek.forEach((element) => {
          element.subs.forEach((value) => {
            if (firstItem.Targykod == value.Targykod) {
              value.color = "#038cac";
              value.text_color = "white";
            }
            // if(item.F2 == value.F2) {
            //   value.color = this.utokovetelmenyColors[item.hanyadik-1];
            //   value.text_color = "white"
            // }
          });
        });
      });
      if (
        firstItem.Felev_szam.toString() != list.toString() &&
        parseInt(firstItem.Felev_szam) < parseInt(list)
      ) {
        this.csere(itemID, list, osszes);
      } else if (
        firstItem.Felev_szam.toString() != list.toString() &&
        parseInt(firstItem.Felev_szam) > parseInt(list)
      ) {
        osszes = [];
        this.csere(itemID, list, osszes);
      }
    },

    async getDatas() {
      try {
        this.loadingCircular = true;
        const response = await this.$http.post(
          "getSubjectsMatrix/",
          this.URLSamplePlan
        );
        this.information = response.information[0];
        this.felevek = response.felevek;
        this.felevekAlap = this.felevek;
        this.subjects = response.types[0];
        this.allSubjects = response.types;
        response.types.forEach((element, index) => {
          if (index > 0) {
            this.secondSubjects.push(element);
          }
        });
        for (let index = 0; index < this.felevek.length; index++) {
          this.felevCredits.push(0);
        }
        for (let index = 0; index < response.types.length; index++) {
          var credit = {
            credit: 0,
            db: 0,
          };
          this.getSecondAllCredit.push(credit);
        }
        this.felevek.forEach((item) => {
          this.allCredit += item.creditPerFelev;
          if (item.nullCreditSub != 0) {
            this.allNullCreditSub += item.nullCreditSub;
          }
        });
        this.maxRow = Math.max.apply(
          Math,
          response.felevek.map(function (o) {
            return o.creditPerFelev;
          })
        );
        this.maxRowAlap = this.maxRow;
        setTimeout(() => (this.loadingCircular = false), 500);
      } catch (e) {
        console.error(e);
      }
    },

    csere(code, felev_szam, utokovetelmenyek) {
      try {
        var eredeti = this.subjects.subjects;
        var tantargy = {};
        //kikeresem a tantárgyat.
        eredeti.forEach((item) => {
          if (item.Targykod == code && item.Felev_szam != felev_szam) {
            item.Felev_szam = felev_szam;
            tantargy = item;
          }
        });
        var felevekk = [];
        var felevekSeged = [];
        //az utokovetelmenyeket eltolom ha kell
        utokovetelmenyek.forEach((element) => {
          eredeti.forEach((item) => {
            if (
              element.Targykod == item.Targykod &&
              parseInt(element.Felev_szam) - parseInt(tantargy.Felev_szam) <
                parseInt(element.hanyadik)
            ) {
              var seged =
                parseInt(tantargy.Felev_szam) + parseInt(element.hanyadik);
              item.Felev_szam = seged.toString();
            }
          });
        });

        eredeti.forEach((item) => {
          if (!felevekSeged.includes(parseInt(item.Felev_szam))) {
            felevekSeged.push(parseInt(item.Felev_szam));
          }
        });

        this.subjects.subjects = eredeti;
        felevekSeged.sort(function (a, b) {
          return a - b;
        });
        var felevekSeged2 = [];
        for (
          let index = 1;
          index <= felevekSeged[felevekSeged.length - 1];
          index++
        ) {
          felevekSeged2.push(index);
        }
        var allCredits = 0;
        felevekSeged2.forEach((item) => {
          var felev = {};
          felev.sem = "";
          felev.creditPerFelev = 0;
          felev.subs = [];
          felev.sem = item.toString();
          allCredits = 0;
          var nullCreditSub = 0;
          eredeti.forEach((element) => {
            if (element.Felev_szam == item.toString()) {
              element.eddigi_kredit = allCredits;
              if (parseInt(element.Targy_kredit) == 0) {
                allCredits += 1;
                nullCreditSub += 1;
              } else {
                allCredits += parseInt(element.Targy_kredit);
              }
              felev.subs.push(element);
            }
          });
          felev.nullCreditSub = nullCreditSub;
          felev.creditPerFelev = allCredits;
          felevekk.push(felev);
        });

        var maxRoww = 0;
        maxRoww = parseInt(
          Math.max.apply(
            Math,
            felevekk.map(function (o) {
              return o.creditPerFelev;
            })
          )
        );
        // ez az üres kitöltés a maradékra
        felevekk.forEach((item) => {
          var plusz = {};
          plusz.Targykod = item.sem;
          plusz.Targynev = Math.floor(Math.random() * 1000);
          plusz.Felev_szam = item.sem;
          plusz.Targy_kredit =
            parseInt(maxRoww) - parseInt(item.creditPerFelev);
          plusz.eddigi_kredit = parseInt(item.creditPerFelev);
          item.subs.push(plusz);
        });
        let countFelevCredits = this.felevCredits.length;
        for (let index = countFelevCredits; index < maxRoww; index++) {
          this.felevCredits.push(0);
        }

        this.felevek = felevekk;
        this.maxRow = maxRoww;
      } catch (e) {
        console.error(e);
      }
    },
    async tematikaMegtekintes(F2) {
      try {
        const post = {
          sub: F2,
        };
        const response = await this.$http.post("getSubjectDetails/", post);
        this.subject = response.details;
      } catch (e) {
        console.error(e);
      }
      this.dialog = true;
    },
    colorChange(type) {
      this.felevek.forEach((item) => {
        item.subs.forEach((element) => {
          element.color = "white";
          element.text_color = "#038cac";
        });
      });
      if (type == 1) {
        if (this.freeSubsCount % 2 == 0) {
          this.felevek.forEach((item) => {
            item.subs.forEach((element, id) => {
              if (
                element.Elokovetelmeny_leiras_1 == null &&
                element.Elokovetelmeny_leiras_2 == null &&
                Number.isNaN(Number.parseFloat(element.Targynev))
              ) {
                element.color = "#81C784";
                element.text_color = "white";
              }
            });
          });
        } else {
          this.felevek.forEach((item) => {
            item.subs.forEach((element, id) => {
              element.color = "white";
              element.text_color = "#038cac";
            });
          });
        }
        this.freeSubsCount++;
      } else if (type == 0) {
        this.felevek.forEach((item) => {
          this.felevek = [];
          this.felevek = this.felevekAlap;
          this.maxRow = this.maxRowAlap;
          item.subs.forEach((element) => {
            element.color = "white";
            element.text_color = "#038cac";
          });
        });
      }
    },

    countCredits(f, index) {
      var cb = document.getElementById(f.Targykod);
      if (index == -1) {
        if (cb.checked == true) {
          this.felevCredits[f.Felev_szam - 1] += parseInt(f.Targy_kredit);
          this.getAllCredit += parseInt(f.Targy_kredit);
        } else {
          this.felevCredits[f.Felev_szam - 1] -= parseInt(f.Targy_kredit);
          this.getAllCredit -= parseInt(f.Targy_kredit);
        }
      } else {
        if (cb.checked == true) {
          this.getSecondAllCredit[index].credit += parseInt(f.Targy_kredit);
          this.getSecondAllCredit[index].db++;
        } else {
          this.getSecondAllCredit[index].credit -= parseInt(f.Targy_kredit);
          this.getSecondAllCredit[index].db--;
        }
      }
      this.$forceUpdate();
    },

    selectSubject(tantargy) {
      this.felevek.forEach((item) => {
        item.subs.forEach((element) => {
          this.$set(element, "color", "white");
          this.$set(element, "text_color", "#038cac");
        });
      });

      this.secondSubjects.forEach((item) => {
        item.subjects.forEach((element) => {
          this.$set(element, "color", "white");
          this.$set(element, "text_color", "#038cac");
        });
      });

      tantargy.color = "#FB8C00";
      tantargy.text_color = "white";

      var tantargyak = [];
      var t = [];
      var tSeged = tantargy;
      t[0] = tantargy;
      var szamlalo = 0;
      var elokovetelmenyek = [];
      var van = true;
      var osszes = [];
      var osszesUto = [];
      this.allSubjects.forEach((item) => {
        item.subjects.forEach((element) => {
          tantargyak.push(element);
        });
      });
      while (van == true) {
        szamlalo++;
        var retU = this.utoKovetelmenyek(t, tantargyak, szamlalo);
        van = retU.van;
        t = retU.utokov;
        retU.utokov.forEach((element) => {
          osszesUto.push(element);
        });
      }
      osszesUto.forEach((item) => {
        this.felevek.forEach((element) => {
          element.subs.forEach((value) => {
            if (item.Targykod == value.Targykod) {
              value.color = this.utokovetelmenyColors[item.hanyadik - 1];
              value.text_color = "white";
            }
          });
        });
        this.secondSubjects.forEach((element) => {
          element.subjects.forEach((value) => {
            if (item.Targykod == value.Targykod) {
              value.color = this.utokovetelmenyColors[item.hanyadik - 1];
              value.text_color = "white";
            }
          });
        });
      });
      t = [];
      t[0] = tSeged;
      szamlalo = 0;
      elokovetelmenyek = [];
      van = true;
      if ("elokov" in tantargy) {
        van = true;
        if (tantargy.elokov.length > 0) {
          while (van == true) {
            szamlalo++;
            var ret = this.elokovetelmenyekFunction(
              t,
              tantargyak,
              szamlalo,
              osszes
            );
            t = ret.elokov;
            t.forEach((item) => {
              osszes.push(item);
            });
            van = ret.talalat;
          }

          osszes.forEach((item) => {
            this.felevek.forEach((element) => {
              element.subs.forEach((value) => {
                if (item.Targykod == value.Targykod) {
                  value.color = this.elokovetelmenyColors[item.hanyadik - 1];
                  value.text_color = "white";
                }
              });
            });
            this.secondSubjects.forEach((element) => {
              element.subjects.forEach((value) => {
                if (item.Targykod == value.Targykod) {
                  value.color = this.elokovetelmenyColors[item.hanyadik - 1];
                  value.text_color = "white";
                }
              });
            });
          });
        }
      }

      this.$forceUpdate();
    },

    elokovetelmenyekFunction(t, tantargyak, szamlalo, osszes) {
      var ret = {};
      var osszelokov = [];
      var talalat = false;
      var elokov = [];
      t.forEach((element) => {
        element.elokov.forEach((item) => {
          tantargyak.forEach((value) => {
            if (item == value.Targykod) {
              var plusz = {};
              plusz = value;
              plusz.hanyadik = szamlalo;
              talalat = true;
              plusz.raepul = element.Targykod;
              elokov.push(plusz);
            }
          });
        });
      });
      ret.elokov = elokov;
      ret.talalat = talalat;
      return ret;
    },
    utoKovetelmenyek(t, tantargyak, szamlalo) {
      var ret = {};
      ret.van = false;
      ret.utokov = [];
      t.forEach((element) => {
        tantargyak.forEach((item) => {
          item.elokov.forEach((value) => {
            if (value == element.Targykod) {
              ret.van = true;
              ret.utokov.push(item);
              item.hanyadik = szamlalo;
            }
          });
        });
      });
      return ret;
    },
  },
};
</script>
<style scoped>
td {
  font-size: 12px !important;
  height: 20px !important;
}
td:hover {
  background-color: white;
}
tr:hover {
  background-color: white !important;
}
.btn {
  font-size: 11px !important;
}
.header-font {
  font-size: 12px;
}
a {
  text-decoration: none;
  color: black;
}
.textflex {
  cursor: pointer;
  font-size: 1rem;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-align: center;
}
.color-box-yellow {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #fb8c00;
}
.color-box-darkblue {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #061d30;
}
.color-box-lightblue {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #e8f4fd;
}
.color-box-darkred {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #3c0000;
}
.color-box-lightred {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #fce8ef;
}
.color-box-green {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #81c784;
}
.color-box-darkgreen {
  display: inline-block;
  height: 2vh;
  width: 2vh;
  background-color: #18960c;
}
.header-row {
  background: linear-gradient(135deg, #00adcb 20%, #0c3c60);
}
.header-text {
  font-size: 1.5rem;
  font-weight: 600;
}
.v-row {
  margin: 50px !important;
  padding: 0;
}
.v-col {
  margin: 0;
  padding: 0;
}
</style>
